<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <div
      class="loading d-flex justify-content-center align-items-center"
      v-if="$store.getters['announcements/isFetching']($route.query)"
    >
      <div class="spinner-border" role="status"></div>
    </div>

    <p class="text-muted" v-else-if="!announcements.length">
      Tidak ada pengumuman.
    </p>

    <AnnouncementItem
      class="mb-16"
      v-for="announcement of announcements"
      :announcement="announcement"
      :key="announcement.id"
    />

    <Pagination
      class="mt-16"
      v-if="meta && meta.last_page > 1"
      :current="meta.current_page"
      :last="meta.last_page"
    />
  </main>
</template>

<script>
import AnnouncementItem from '../../components/announcement/AnnouncementItem.vue';
import Pagination from '../../components/table/Pagination.vue';

export default {
  components: { AnnouncementItem, Pagination },

  computed: {
    announcements() {
      return this.$store.getters['announcements/get'](this.$route.query);
    },

    meta() {
      return this.$store.getters['announcements/getMeta'](this.$route.query);
    },
  },

  metaInfo: {
    title: 'Beranda',
  },

  methods: {
    fetch() {
      this.$store.dispatch('announcements/maybeFetch', this.$route.query);
    },
  },

  watch: {
    '$route.query'() {
      this.fetch();
    },
  },

  beforeMount() {
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
.loading {
  height: rem(80);
}
</style>
